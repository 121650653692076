<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('card.expire') }}</span>
          <el-date-picker class="inp" value-format="YYYY-MM-DD" v-model="formData.valTime" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t("card.number") }}</span>
          <el-input class="inp" size="small" v-model="formData.cardNum"
            :placeholder="$t('g.please_input') + $t('card.number')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.user_id') }}</span>
          <el-input class="inp" size="small" v-model="formData.valUserId"
            :placeholder="$t('g.please_input') + $t('openSlotsLit.user_id')">
          </el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.mobile') }}</span>
          <el-input class="inp" size="small" v-model="formData.valPhone"
            :placeholder="$t('g.please_input') + $t('member.mobile')"></el-input>
        </div>
        <div class=" item">
          <span class="label">
            {{ $t('device.device_id') }}
          </span>
          <el-input class="inp" size="small" v-model="formData.valBatId"
            :placeholder="$t('g.please_input') + $t('device.device_id')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" :stripe="true" @row-click="func.onOpenDetailsDlg" style="width: 100%">
        <el-table-column prop="Id" label="Id" width="60">
        </el-table-column>
        <el-table-column prop="Number" :label="$t('card.number')" width="140">
        </el-table-column>
        <el-table-column prop="UserId" :label="$t('openSlotsLit.user_id')" width="80">
          <template #default="scope">
            {{ scope.row.UserId == 0 ? '' : scope.row.UserId }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('card.bind_user')" width="100">
          <template #default="scope">
            {{ scope.row.UserInfo.Nickname }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('member.mobile')" width="120">
          <template #default="scope">
            {{ scope.row.UserInfo.Mobile }}
          </template>
        </el-table-column>
        <el-table-column prop="DepositMoney" width="60" :label="$t('device.deposit')">
        </el-table-column>
        <el-table-column prop="AgentCode" width="80" :label="$t('g.operator')">
        </el-table-column>
        <el-table-column prop="UserInfo.Money" width="60" :label="$t('member.balance')">
        </el-table-column>
        <el-table-column prop="BatId" width="160" :label="$t('device.device_id')">
        </el-table-column>
        <el-table-column width="80" :label="$t('expenses.status')">
          <template #default="scope">
            <el-tag   :class="scope.row.UserInfo?.IsDisable ? 'tag-fail' : 'tag-success'"> 
              {{ scope.row.UserInfo?.IsDisable == false ? $t('expenses.enable') : $t('expenses.disable') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="AllEndAtStr" width="180" :label="$t('card.expire')">
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="200" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column width="300" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <div>
              <el-button @click="func.onOpenEditDlg(scope.row)" class="btn-edit" type="primary">{{
                $t('g.edit')
              }}</el-button>
              <template v-if="scope.row.UserId == 0">
                <el-button type="info">{{ $t('chart.order_types.charge')
                  }}</el-button>
                <el-button type="info">{{
                  $t('card.renewal') }}</el-button>
                <el-button type="info">{{ $t('card.unbind') }}</el-button>
              </template>
              <template v-else>
                <el-button @click="func.onAddBalance(scope.row)" class="btn-edit"
                  v-if="scope.row.UserInfo?.XchOpenid">{{
                    $t('chart.order_types.charge')
                  }}</el-button>
                <el-button v-else type="info">{{
                  $t('chart.order_types.charge')
                }}</el-button>
                <el-button @click="func.onAddRenewal(scope.row)" class="btn-edit" type="primary">{{
                  $t('card.renewal')
                }}</el-button>
                <el-button @click="func.unBindUser(scope.row)" class="btn-edit" type="primary">{{
                  $t('card.unbind')
                }}</el-button>

                <!-- <el-tag style="margin-left: 0.5rem;" type="success" @click="func.onAddBalance(scope.row)"
                  v-if="scope.row.UserInfo?.XchOpenid">{{
                    $t('chart.order_types.charge')
                  }}</el-tag>
                <el-tag style="margin-left: 0.5rem;cursor: default;" type="info" v-else>{{
                  $t('chart.order_types.charge')
                }}</el-tag>
                <el-tag style="margin-left: 0.5rem;" type="success" @click="func.onAddRenewal(scope.row)">{{
                  $t('card.renewal') }}</el-tag>
                <el-tag style="margin-left: 0.5rem;" type="success" @click="func.unBindUser(scope.row)">{{
                  $t('card.unbind') }}</el-tag> -->
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize }}
        </div>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.openDetailDlg" align-center>
      <el-descriptions border column="3">
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('card.number')">{{ formData.curData.Number }}</el-descriptions-item>
        <el-descriptions-item :label="$t('openSlotsLit.user_id')">{{ formData.curData.UserId == 0 ? '' :
          formData.curData.UserId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('card.bind_user')">
          {{ formData.curData.UserInfo?.Nickname }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('member.mobile')">{{ formData.curData.UserInfo?.Mobile
          }}</el-descriptions-item>
        <!-- <el-descriptions-item label="购买套餐">
          <div v-if="formData.curData.UserInfo?.UserComboInfo?.ComboId == 0">/</div>
          <div v-else>{{ func.formMatCombo(formData.curData.UserInfo?.UserComboInfo?.Combo) }}</div>
        </el-descriptions-item> -->
        <el-descriptions-item :label="$t('device.deposit')">{{ formData.curData.DepositMoney }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.operator')">{{ formData.curData.AgentCode }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.balance')">{{ formData.curData.UserInfo?.Money
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.device_id')">{{ formData.curData.BatId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('expenses.status')">
          <el-tag :class="formData.curData.UserInfo?.IsDisable ? 'tag-fail' : 'tag-success'">
            {{ formData.curData.UserInfo?.IsDisable == false ? $t('expenses.enable') : $t('expenses.disable') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('card.expire')">{{ formData.curData.AllEndAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.residue_degree')"> <span
            v-if="formData.curData.UserInfo?.UserComboInfo?.Combo?.Type == 2">{{
              formData.curData.UserInfo?.UserComboInfo?.RemainTimes }}</span></el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <el-dialog :title="$t('card.bind_user')" v-model="formData.openBindUserDlg" width="600px">
      <el-form label-width="80px" :model="formData.bindUser">
        <el-form-item :label="$t('card.bind_user')">
          <el-select v-model="formData.bindUser.userId" filterable remote reserve-keyword
            :placeholder="$t('card.please_input_account')" :remote-method="func.searchUsers" :loading="loading"
            clearable>
            <el-option v-for="item in formData.users" :key="item.Id" :label="item.Account" :value="item.Id" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('card.bind_package')">
          <!-- <el-input size="small" type="number" v-model="formData.card.comboId"></el-input> -->
          <el-select class="inp" v-model="formData.card.comboId" :placeholder="$t('card.bind_package')">
            <el-option v-for="item in formData.packages" :key="item.Id" :label="func.formMatCombo(item)"
              :value="item.Id">
              <span style="margin-right:20px">{{ item.AgentCode }}</span>
              <span style="margin-right:20px">{{ func.formMatCombo(item) }}</span>
              <span style="">{{ item.Name }}</span>
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.doBindUser()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('card.buyCombo')" v-model="formData.openRenewalDlg" width="800px" align-center>
      <el-form label-width="160px" class="e-form">
        <el-form-item :label="$t('g.operator')" v-if="isAdmin()">
          <el-select class="inp" v-model="formData.card.agentCode" :placeholder="$t('g.choose')"
            @change="func.selectAgentCode">
            <el-option v-for="item in render.operatorList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('card.beforeExp')" style="width:380px">
          <el-input class="inp" :value="formData.card.AllEndAtStr" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('card.buyCombo')">
          <el-select class="inp" v-model="formData.card.comboId" :placeholder="$t('card.buyCombo')">
            <el-option v-for="item in formData.packages" :key="item.Id" :label="func.formMatCombo(item)"
              :value="item.Id">
              <span style="margin-right:20px">{{ item.AgentCode }}</span>
              <span style="margin-right:20px">{{ func.formMatCombo(item) }}</span>
              <span style="">{{ item.Name }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item :label="$t('card.afterExp')">
          2024-10-04 14:36:15
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.renewCombo()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="账号充值" v-model="formData.openBalanceDlg" width="600px" align-center>
      <el-form label-width="160px" class="e-form">
        <el-form-item label="卡号" style="width:380px">
          {{ formData.card.number }}
        </el-form-item>
        <el-form-item label="账号ID" style="width:380px">
          {{ formData.card.userId }}
        </el-form-item>
        <el-form-item label="当前余额" style="width:380px">
          {{ formData.card.money }}
        </el-form-item>
        <el-form-item label="充值金额">
          <el-input size="large" v-model="formData.card.number2"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.onTopUp()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="formData.card.operate == 0 ? $t('device.add') : $t('g.edit')"
      v-model="formData.openAddAndEditDlg" width="800px" align-center>
      <el-form label-width="160px" class="e-form" :model="formData.card" :rules="rules">
        <el-form-item :label="$t('card.number')" prop="number">
          <el-input v-model="formData.card.number"></el-input>
        </el-form-item>
        <el-form-item :label="$t('g.agent_code')" v-if="isAdmin()">
          <el-select class="inp" v-model="formData.card.agentCode" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operatorList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('g.operator')">
          <el-cascader style="width: 192px;flex: 1;" :clearable="true" v-model="formData.card.CodePath"
            @change="func.selectAgentCode" :props="formData.props2" :options="render.cascade_options_direct" />
        </el-form-item>
        <el-form-item :label="$t('card.deposit')">
          <el-input type="number" v-model="formData.card.deposit"></el-input>
        </el-form-item>
        <el-form-item :label="$t('card.accountType')"
          v-if="formData.card.operate == 0 || formData.card.isBindUser == 0">
          <el-select class="inp" v-model="formData.card.userType" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.accountType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('card.bindWeChat')" v-if="formData.card.userType == 0">
          <el-input type="string" v-model="formData.card.userId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('card.bind_package')"
          v-if="formData.card.operate == 0 || formData.card.isBindUser == 0" prop="comboId">

          <el-select class="inp" v-model="formData.card.comboId" :placeholder="$t('card.bind_package')" value-key="Id">
            <el-option v-for="item in formData.packages" :key="item.Id" :label="func.formMatCombo(item)"
              :value="item.Id" @click.native="func.onGetComboMoney(item)">
              <span style="margin-right:20px">{{ item.AgentCode }}</span>
              <span style="margin-right:20px">{{ func.formMatCombo(item) }}</span>
              <span style="">{{ item.Name }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('member.mobile')">
          <el-input v-model="formData.card.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="$t('member.battery_status') + 'ID'">
          <el-input v-model="formData.card.batId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('expenses.status')">
          <el-switch size="big" v-model="formData.card.IsDisable" :active-text="$t('expenses.enable')"
            :inactive-text="$t('expenses.disable')" />
        </el-form-item>
        <el-form-item :label="$t('card.cashRece')" v-if="formData.card.operate == 0">
          {{ Number(formData.card.deposit) + formData.card.comboMoney || 0 }}
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.addCard()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue'
import { shortcuts, operatorList, cascade_options_direct } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import i18n from '@/data/i18n/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import { isAdmin } from '@/data/token'

export default {
  name: 'BatteryList',
  components: {
  },
  setup() {
    const t = i18n.global.t
    const tableData = []

    const render = {
      shortcuts,
      operatorList,
      cascade_options_direct,
      accountType: [
        {
          label: t('card.WeChat'),
          value: 0
        },
        {
          label: t('card.Automatic'),
          value: 1
        }
      ],
    }
    const formData = reactive({
      tableData,
      card: {
        number: 0,
        deposit: 0,
      },
      bindUser: {},
      packages: [],
      valTime: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      openBindUserDlg: false,
      openAddAndEditDlg: false,
      openDetailDlg: false,
      openRenewalDlg: false,
      openBalanceDlg: false,
      users: [],
      loadingUser: false,
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
      props2: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
    })
    const rules = reactive({
      number: [{ required: true, message: '请输入卡号', trigger: 'change' }],
      // comboId: [{ required: true, message: '请购买套餐', trigger: 'change' }],
      // valTime: [{ required: true, message: '请输入起止时间', trigger: 'change' }],
    })
    const func = {
      onAddBalance(row) {
        formData.card = {}
        formData.card.id = row.Id
        formData.card.number = row.Number
        formData.card.userId = row.UserId
        // formData.card.number2 = ''
        formData.card.money = row.UserInfo.Money
        formData.openBalanceDlg = true
      },
      onAddRenewal(row) {
        console.log(JSON.stringify(row));
        formData.card = {}
        // formData.card = row
        formData.card.id = row.Id
        formData.card.deposit = row.DepositMoney
        formData.card.agentCode = row.AgentCode
        formData.card.codePath = row.CodePath
        formData.card.number = row.Number
        formData.card.AllEndAtStr = row.AllEndAtStr
        func.loadPackages(row.CodePath)
        formData.openRenewalDlg = true
      },
      async renewCombo() {
        let params = {}
        if (formData.card.id) {
          params.id = formData.card.id
        }
        if (formData.card.comboId) {
          params.comboId = formData.card.comboId
        }
        let res = await axios.post('/admin/card/renewcombo', params)
        ElMessage({
          type: 'success',
          message: res
        })
        formData.openRenewalDlg = false
        // 重置表单
        formData.card = {}
        func.loadData()
      },
      onOpenAddDlg() {
        formData.card = {}
        formData.card.operate = 0
        formData.card.accountType = 0
        formData.card.IsDisable = true
        formData.card.comboId = ''
        formData.card.comboMoney = 0
        func.loadPackages()
        formData.openAddAndEditDlg = true
      },
      onOpenEditDlg(row, column, e) {
        console.log(JSON.stringify(row));

        formData.card.operate = 1
        formData.card.id = row.Id
        formData.card.deposit = row.DepositMoney
        formData.card.agentCode = row.AgentCode
        formData.card.number = row.Number
        formData.card.userId = row.UserId == 0 ? '' : row.UserId
        formData.card.isBindUser = row.UserId
        formData.card.endAtStr = row.UserInfo.UserComboInfo?.EndAtStr
        formData.card.mobile = row.UserInfo?.Mobile
        formData.card.batId = row.BatId
        formData.card.CodePath = row.CodePath
        formData.card.comboId = row.UserInfo?.UserComboId?.ComboId || ''
        formData.card.money = row.money
        formData.card.userType = row.userType
        formData.card.IsDisable = !row.UserInfo?.IsDisable
        func.loadPackages()
        formData.openAddAndEditDlg = true
      },
      onOpenDetailsDlg(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        console.log(JSON.stringify(row), 2);
        // formData.curData = []
        formData.curData = row;
        formData.openDetailDlg = true;
      },
      async onTopUp() {
        let params = {}
        console.log(JSON.stringify(formData.card));

        if (formData.card.userId && formData.card.id && formData.card.number2 > 0) {
          params.id = formData.card.id
          params.userId = formData.card.userId
          params.money = formData.card.number2
        } else {
          return ElMessage({
            type: 'error',
            message: '参数错误，' + t('err_code.20017')
          })
        }

        let res = await axios.post('/admin/card/recharge', params)
        ElMessage({
          type: 'success',
          message: res
        })
        formData.openBalanceDlg = false
        // 重置表单
        formData.card = {}
        func.loadData()

      },
      async addCard() {
        //formData.card.operate  0 add 1 edit  
        let data = formData.card
        // console.log(JSON.stringify(form));

        let params = {}
        if (data.number) {
          params.number = data.number
        }
        // if(data.userType==0){

        // }
        if (data.userType == undefined && formData.card.comboId) {
          return ElMessage({
            type: 'error',
            message: '账号类型为空，' + t('err_code.20017')
          })
        }
        if (data.userType == 0 && !data.userId && formData.card.comboId) {
          return ElMessage({
            type: 'error',
            message: '账号为空，' + t('err_code.20017')
          })
        }
        if (data.deposit) {
          params.deposit = data.deposit
        }
        if (data.agentCode) {
          params.agentCode = data.agentCode
        }

        if (data.CodePath) {
          params.agentPath = data.CodePath
        }
        if (data.userType || data.userType == 0) {
          params.userType = data.userType
        }

        if (data.userType == 0) {
          if (data.userId) {
            params.userId = data.userId
          } else {
            return ElMessage({
              type: 'error',
              message: '微信ID为空，' + t('err_code.20017')
            })
          }

        }

        params.IsDisable = !data.IsDisable
        if (data.mobile) {
          params.mobile = data.mobile
        }
        if (data.batId) {
          params.batId = data.batId
        }
        if (formData.card.comboId) {
          params.comboId = formData.card.comboId
        }
        if (formData.card.operate == 0) {

          if (data.money) {
            params.money = data.money
          }
        }
        if (formData.card.operate == 1) {
          params.id = data.id
        }

        let url = ''
        if (formData.card.operate == 0) {
          url = '/admin/card/add'
        } else {
          url = '/admin/card/edit'
        }
        // if (data.id > 0) {

        // }
        let res = await axios.post(url, params)
        console.log('------doBindUser---')
        console.log(res)
        ElMessage({
          type: 'success',
          message: res
        })
        formData.openAddAndEditDlg = false
        // 重置表单
        formData.card = {}
        func.loadData()
      },

      search() {
        console.log('formData', formData)
        func.loadData()
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.cardNum) {
            params.cardNum = formData.cardNum
          }
          if (formData.userAccount) {
            params.userAccount = formData.userAccount
          }
          if (formData.valOperator) {
            params.CodePath = formData.valOperator
          }
          if (formData.valUserId) {
            params.userId = formData.valUserId
          }
          if (formData.valPhone) {
            params.mobile = formData.valPhone
          }
          if (formData.valBatId) {
            params.batId = formData.valBatId
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          console.log(JSON.stringify(formData.valTime));
          console.log(JSON.stringify(params));

          if (formData.valDevName) {
            params.devId = formData.valDevName
          }
          let res = await axios.get(`/admin/card/get`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreatedAt);
            if (item.UserInfo && item.UserInfo.UserComboInfo) {
              item.EndAtStr = item.UserInfo.UserComboInfo.EndAt ? toDay(item.UserInfo.UserComboInfo.EndAt) : '';
              item.AllEndAtStr = item.UserInfo.UserComboInfo.AllEndAt ? toDay(item.UserInfo.UserComboInfo.AllEndAt) : '';
            }
            if (!item.UserInfo) {
              item.UserInfo = {}
              item.UserInfo.UserComboInfo = {}
            }

          })
          formData.tableData = res.List
          // console.log(JSON.stringify(formData.tableData));
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      },
      selectAgentCode() {
        func.loadPackages()
      },
      async loadPackages(agentCode = '') {
        let params = {
        }
        if (formData.card.agentCode) {
          params.agentCode = formData.card.agentCode
        }
        if (agentCode) {
          params.codePath = agentCode
        }
        if (formData.card.CodePath) {
          params.codePath = formData.card.CodePath
        }
        try {
          let res = await axios.get(`/admin/combo/all`, { params })
          formData.packages = []
          for (let item of res) {
            if (item.Status == 1) {
              formData.packages.push(item)
            }
          }
          // formData.packages = res
        } catch (e) {
          console.log("loadData", e)
        }
      },
      rowBindClick(row, column, e) {
        console.log('rowBindClick', row, column, e)
        formData.bindUser.id = row.Id
        func.loadPackages(row.CodePath)
        formData.openBindUserDlg = true
      },
      onGetComboMoney(item) {
        formData.card.comboMoney = Number(item.Price)
        // console.log(JSON.stringify(item));

      },
      formMatCombo(item) {
        const t = i18n.global.t
        let unit = t('card.month')
        if (item.Type == 2) {
          unit = t('card.times')
        } else if (item.Type == 3) {
          unit = '天'
        }

        return `${item.Price}${t('card.currency')}/${item.Num}${unit}`
      },
      async searchUsers(account) {
        console.log('---searchUsers--')
        console.log(account)
        if (account) {
          formData.loadingUser = true
          let params = {
            account,
          }
          try {
            let res = await axios.get(`/admin/user/findUser`, {
              params
            })
            formData.loadingUser = false
            console.log('-------search User')
            // console.log(res)
            formData.users = res
          } catch (e) {
            console.log("loadData", e)
            formData.loadingUser = false
          }
        } else {
          formData.users = []
        }
      },
      doBindUser() {
        let data = formData.bindUser;
        // console.log(JSON.stringify(data));

        // console.log(data)
        // const t = i18n.global.t
        // let package_id = formData.card.comboId
        // let comboLabel = t('card.bind_tips')
        // for (let o of formData.packages) {
        //   if (package_id == o.Id) {
        //     console.log(JSON.stringify(o));

        //     comboLabel = comboLabel.replace('xx/xx', '')
        //     break
        //   }
        // }
        // console.log(JSON.stringify(comboLabel));

        // 确定为该卡绑定xx/xx套餐吗

        // ElMessageBox.confirm(comboLabel, t('tips'), {
        //   confirmButtonText: t('confirm'),
        //   cancelButtonText: t('cancel'),
        //   type: 'warning'
        // }).then(async () => {
        //   let res = await axios.post("/admin/card/bindUser", data)
        //   console.log('------doBindUser---')
        //   console.log(res)
        //   ElMessage({
        //     type: 'success',
        //     message: res
        //   })
        // }).then(() => {

        // })
        //   .catch(() => {
        //   })
        (async function () {
          console.log('------doBindUser---')
          let res = await axios.post("/admin/card/bindUser", data)
          console.log('------doBindUser---')
          console.log(res)
          ElMessage({
            type: 'success',
            message: res
          })
        })();


        formData.openBindUserDlg = false
        // 重置表单
        formData.bindUser = {}
        setTimeout(() => {
          func.loadData()
        }, 500)

      },
      unBindUser(row, column, e) { //解绑用户
        // formData.bindUser.id = row.Id
        let data = {
          id: row.Id
        };
        const t = i18n.global.t
        let comboLabel = "解除绑定会清空绑定用户所有信息，是否继续？";
        ElMessageBox.confirm(comboLabel, t('tips'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
          type: 'warning'
        }).then(async () => {
          let res = await axios.post("/admin/card/unbindCard", data)
          console.log('------doBindUser---')
          console.log(res)
          ElMessage({
            type: 'success',
            message: res
          })
          setTimeout(() => {
            func.loadData()
          }, 2000)
        }).catch(() => {
        })
        // (async function (){
        //     let res = await axios.post("/admin/card/unbindCard", data)
        //     console.log('------doBindUser---')
        //     console.log(res)
        //     ElMessage({
        //       type: 'success',
        //       message: res
        //     })
        // })();
        // func.loadData()

      }
    }
    onMounted(() => {
      func.loadData()
      func.loadPackages()
    })
    return {
      func,
      render,
      formData,
      isAdmin,
      rules
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .e-form {
    display: flex;
    // justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    // &.line-3 {
    //   :deep(.el-form-item) {
    //     width: 33%;
    //   }
    // }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  .e-form {
    display: flex;
    flex-wrap: wrap;
    // .inp{
    //   width: 192px;
    // }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
  }

}
</style>