<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valCode"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">处理状态</span>
          <el-select class="inp" size="small" v-model="formData.processing_status" :placeholder="$t('g.choose')">
            <el-option label="所有" :value="-1"></el-option>
            <el-option label="已逾期" :value="0"></el-option>
            <el-option label="逾期已续租" :value="1"></el-option>
            <el-option label="逾期已退租" :value="2"></el-option>
            <el-option label="手动结束" :value="3"></el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">完结状态</span>
          <el-select class="inp" size="small" v-model="formData.completion_status" :placeholder="$t('g.choose')">
            <el-option label="所有" :value="-1"></el-option>
            <el-option label="未完结" :value="0"></el-option>
            <el-option label="已完结" :value="1"></el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">昵称</span>
          <el-input class="inp" size="small" v-model="formData.nickname" placeholder="昵称"></el-input>
        </div>
        <div class="item">
          <span class="label">手机号</span>
          <el-input class="inp" size="small" v-model="formData.phone_number" placeholder="手机号"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%">
        <el-table-column prop="Nnickname" label="用户昵称" width="80">
        </el-table-column>
        <el-table-column prop="Mobile" label="用户手机号" width="130">
        </el-table-column>
        <el-table-column prop="OverdueFee" label="系统逾期费用" width="120">
        </el-table-column>
        <el-table-column prop="FinalOverdueFee" label="结算逾期费用" width="120">
        </el-table-column>
        <el-table-column prop="OverDays" label="系统逾期天数" width="120">
        </el-table-column>
        <el-table-column prop="FinalOverdueDay" label="结算逾期天数" width="120">
        </el-table-column>
        <el-table-column width="120" label="处理状态">
          <template #default="scope">
            <el-tag :class="scope.row.ProcessStatus == 0 ? 'tag-fail' : 'tag-success'">
              <span v-if="scope.row.ProcessStatus == 0">已逾期</span>
              <span v-else-if="scope.row.ProcessStatus == 1">逾期已续租</span>
              <span v-else-if="scope.row.ProcessStatus == 2">逾期已退租</span>
              <span v-else-if="scope.row.ProcessStatus == 3">手动结束</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="120" label="完结状态">
          <template #default="scope">
            <el-tag :class="scope.row.Status == 1 ? 'tag-success' : 'tag-fail'">
              <span v-if="scope.row.Status == 0">未完结</span>
              <span v-else-if="scope.row.Status == 1">已完结</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('g.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="MaxMoney" label="最大扣款金额" width="120">
        </el-table-column>
        <el-table-column prop="ImmunizationMoney" label="免扣费金额标准" width="140">
        </el-table-column>
        <el-table-column prop="TotalSpent" label="实际消费总金额" width="140">
        </el-table-column>

        <el-table-column prop="AllEndAt" label="套餐到期时间" width="180">
        </el-table-column>

        <el-table-column prop="GracePeriod" label="免扣天数" width="120">
        </el-table-column>
        <el-table-column prop="DeductionPrice" label="逾期扣款价格" width="120">
        </el-table-column>
        <el-table-column prop="ContinuePercentages" label="续租扣除%" width="120">
        </el-table-column>

        <el-table-column prop="ReturnPercentages" label="退租扣除%" width="120">
        </el-table-column>
        <el-table-column prop="BuyTimes" label="购买次数" width="120">
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="120">
          <template #default="scope">
            <el-button type="primary" size="small" @click="func.examine(scope.row)"
              v-if="scope.row.Status == 0">审核结算</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="审核结算" v-model="formData.addElDailog" width="65%" center align-center>
      <el-descriptions border>
        <el-descriptions-item label="用户昵称">{{ formData.row.Nnickname }}</el-descriptions-item>
        <el-descriptions-item label="手机号码">{{ formData.row.Mobile }}</el-descriptions-item>
        <el-descriptions-item label="系统逾期费用">{{ formData.row.OverdueFee }}</el-descriptions-item>
        <el-descriptions-item label="最大扣款金额">{{ formData.row.MaxMoney }}</el-descriptions-item>
        <el-descriptions-item label="处理状态">
          <span v-if="formData.form.status == 0">已逾期</span>
          <span v-else-if="formData.form.status == 1">逾期已续租</span>
          <span v-else-if="formData.form.status == 2">逾期已退租</span>
          <span v-else-if="formData.form.status == 3">手机结束</span>
        </el-descriptions-item>
      </el-descriptions>
      <div class="dailog-content">
        <div class="activity-content">
          <el-form :model="formData">
            <el-row>
              <el-col :span="11">
                <el-form-item label="结算逾期费用" prop="name" class="input_width">
                  <el-input v-model="formData.form.final_overdue_fee" maxlength="7"
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="formData.form.status == 1">
                    <template #append>元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11" :offset="2">
                <el-form-item label="结算逾期天数" prop="name" class="input_width">
                  <el-input v-model="formData.form.final_overdue_day" maxlength="7"
                    oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :disabled="formData.form.status == 1">
                    <template #append>天</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="11" >
                <el-form-item label="处理状态" >
                  <el-select class="inp" v-model="formData.form.process_status" @change="func.opt_type" :placeholder="$t('g.choose')" style="width: 100%;" :disabled="formData.form.status==1">
                    <el-option v-for="item in render.status" :key="item.val" :label="item.label" :value="item.val">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="11">
                <el-form-item label="状态">
                  <el-select class="inp" v-model="formData.form.status" :placeholder="$t('g.choose')"
                    style="width: 100%;">
                    <el-option label="未完结" :value="0"></el-option>
                    <el-option label="已完结" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="formData.addElDailog = false">取消</el-button>
          <el-button type="primary" @click="func.add"> 确认 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue'
import { shortcuts, operators, cascade_options_direct } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import cfg from '@/common/conf'
import { user, isOperator } from '@/data/token'
import i18n from '@/data/i18n/index'

export default {
  name: 'BillRefund',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('bill.rs.all'),
        value: -1
      },
      {
        label: t('bill.rs.waiting'),
        value: 0
      },
      {
        label: t('bill.rs.refunding'),
        value: 1
      },
      {
        label: t('bill.rs.refunded'),
        value: 2
      },
    ]

    const render = {
      statuses,
      operators,
      shortcuts,
      status: [
        { label: '已逾期', val: 0 },
        { label: '逾期已续租', val: 1 },
        { label: '逾期已退租', val: 2 },
        // {label:'逾期已未付费',val:3},
        // {label:'手动结束',val:4}
      ],
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      orders: {},
      curData: {},
      valTime: '',
      valStatus: -1,
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: '',
      addElDailog: false,
      form: {
        final_overdue_fee: '',
        process_status: '',
        status: 0,
        final_overdue_day: ''
      },
      row: {},
      processing_status: -1,
      completion_status: -1,
      day_type: true,
      money_type: true,
      nickname: '',
      phone_number: '',
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true,
      },
    })
    const func = {
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      pageChange(val) {
        func.loadData(val)
      },
      examine(val) {
        console.log(val.final_overdue_day)
        formData.row = val
        formData.form.process_status = val.ProcessStatus
        formData.form.user_combo_id = val.UserComboID
        formData.form.final_overdue_day = val.FinalOverdueDay
        formData.form.final_overdue_fee = val.FinalOverdueFee
        func.opt_type(val.ProcessStatus)
        formData.addElDailog = true
      },
      async add() {
        console.log(formData.row)
        if (formData.row.MaxMoney < formData.form.final_overdue_fee || formData.row.OverdueFee < formData.form.final_overdue_fee) {
          ElMessage({
            type: "error",
            message: '结算逾期费用或系统逾期费用不可超过最大扣款金额',
          });
          return
        }
        if (formData.row.OverDays - formData.row.GracePeriod < formData.form.final_overdue_day) {
          ElMessage({
            type: "error",
            message: '结算逾期天数不可超过系统逾期天数减去免扣的天数',
          });
          return
        }
        const params = {
          user_combo_id: formData.form.user_combo_id,
          // process_status: formData.form.process_status,
          final_overdue_fee: formData.form.final_overdue_fee,
          final_overdue_day: formData.form.final_overdue_day,
          status: formData.form.status
        }
        let res = await axios.put('/admin/user_combo_overdue', params)
        if (res) {
          ElMessage({
            type: "success",
            message: t("g.success"),
          });
          formData.addElDailog = false
          func.loadData()
        }
        console.log(res, '修改结果 ')
      },
      opt_type(data) {
        if (data == 0) {
          formData.day_type = true
          formData.money_type = true
        } else if (data == 1) {
          formData.day_type = false
          formData.money_type = true
        } else if (data == 2) {
          formData.day_type = true
          formData.money_type = false
        }
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valCode) {
            params.code_path = formData.valCode
          }
          if (formData.nickname) {
            params.nickname = formData.nickname
          }
          if (formData.phone_number) {
            params.mobile = formData.phone_number
          }
          params.process_status = formData.processing_status
          params.status = formData.completion_status

          console.log(formData.processing_status)
          // if(formData.valTime){ 
          //   params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
          //   params.endAt = Math.floor(Date.parse(formData.valTime[1]+" 23:59:59") / 1000)
          // } 
          let res = await axios.get(`/admin/user_combo_overdue`, {
            params
          })
          if (!res) {
            formData.total = 0
          }
          formData.total = res.Total
          res.List.map((item) => {
            item.AllEndAt = toDay(item.AllEndAt)
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    .orders {
      .order {
        display: flex;
        margin-bottom: 0.5rem;
        align-items: center;

        >div {
          margin-right: 1rem;
        }
      }
    }

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;

        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        width: 33%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }
}

.dailog-content {
  margin-top: 40px;
}

.input_width {
  width: 100%;
}
</style>
