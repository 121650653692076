<template>
  <div class="home">
    <div class="left">
      <div class="count">
        <div class="item" @click="func.jump(1, { name: 'valAlert', val: 0 }, 'EboxList')">
          <div class="num">
            <!-- <count-up :duration="1.5" :end-val="devAndBatNum.onlineDev"></count-up> -->
            {{ devAndBatNum.onlineDev }}
          </div>
          <div class="name">{{ $t('home.onlineCab') }}</div>
        </div>
        <div class="item" @click="func.jump(1, { name: 'valAlert', val: 1 }, 'EboxList')">
          <div class="num">
            <!-- <count-up :duration="1.5" :end-val="devAndBatNum.offlineDev"></count-up> -->
            {{ devAndBatNum.offlineDev }}
          </div>
          <div class="name">{{ $t('home.offlineCab') }}</div>
        </div>
        <div class="item" @click="func.jump(1, { name: 'onlineStatus', val: 1 }, 'BatteryList')">
          <div class="num">
            <!-- <count-up :duration="1.5" :end-val="devAndBatNum.onlineBat"></count-up> -->
            {{ devAndBatNum.onlineBat }}
          </div>
          <div class="name">{{ $t('home.onlineBat') }}</div>
        </div>
        <div class="item" @click="func.jump(1, { name: 'onlineStatus', val: 0 }, 'BatteryList')">
          <div class="num">
            <!-- <count-up :duration="1.5" :end-val="devAndBatNum.offlineBat"></count-up> -->
            {{ devAndBatNum.offlineBat }}
          </div>
          <div class="name">{{ $t('home.offlineBat') }}</div>
        </div>
      </div>
      <div class="graph-data">
        <div class="up">
          <div class="title">{{ $t('home.dataStat') }}</div>
          <div class="right">
            <div class="seven-day" :class="{ active: timeType.timeIndex == 7 }"
              @click="func.getStatData(statList.activeIndex, 7)">{{ $t('home.sevenDay') }}
            </div>
            <div class="one-month" :class="{ active: timeType.timeIndex == 30 }"
              @click="func.getStatData(statList.activeIndex, 30)">{{ $t('home.oneMonth') }}</div>
            <div class="period">
              <el-date-picker class="inp" v-model="statList.valTime" value-format="YYYY-MM-DD" size="small"
                type="daterange" unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
                :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts2"
                @change="func.getStatData(statList.activeIndex, 60)">
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="down">
          <div class="list">
            <div class="item" :class="{ active: statList.activeIndex == 1 }" @click="func.getStatData(1)">
              <div class="num "><count-up :duration="2" :end-val="statList.incomeTotal" decimalPlaces="2"></count-up>
              </div>
              <div class="desc">{{ $t('home.income') }}</div>
            </div>
            <div class="delimiter"></div>
            <div class="item" :class="{ active: statList.activeIndex == 2 }" @click="func.getStatData(2)">
              <div class="num"><count-up :duration="2" :end-val="statList.newRentTotal"></count-up></div>
              <div class="desc">{{ $t('home.newUser') }}</div>
            </div>
            <div class="delimiter"></div>
            <div class="item" :class="{ active: statList.activeIndex == 3 }" @click="func.getStatData(3)">
              <div class="num"><count-up :duration="2" :end-val="statList.replaceBatteryTotal"></count-up></div>
              <div class="desc">{{ $t('home.batteryReplace') }}</div>
            </div>
            <div class="delimiter"></div>
            <div class="item" :class="{ active: statList.activeIndex == 4 }" @click="func.getStatData(4)">
              <div class="num"><count-up :duration="2" :end-val="statList.comboTotal"></count-up></div>
              <div class="desc">{{ $t('home.comboNum') }}</div>
            </div>
            <div class="delimiter"></div>
            <div class="item" :class="{ active: statList.activeIndex == 5 }" @click="func.getStatData(5)">
              <div class="num"><count-up :duration="2" :end-val="statList.returnBatteryTotal"></count-up></div>
              <div class="desc">{{ $t('home.battayReturn') }} </div>
            </div>

          </div>
          <div class="graph">
            <div id="myEchart1" ref="myEchart1" style="width:100%;height:100%"></div>
          </div>
        </div>
      </div>
      <div class="jump">
        <div class="title">{{ $t('home.commonFunctions') }}</div>
        <div class="list">
          <div class="item" @click="navTo('MemberList')">
            <el-icon>
              <User />
            </el-icon>
            <!-- <el-iamge class="member-image" fit="fill"></el-iamge> -->
            {{ $t('home.userManage') }}
          </div>
          <div class="item" @click="navTo('EboxList')">
            <el-icon>
              <Memo />
            </el-icon>
            {{ $t('home.cabManage') }}
          </div>
          <div class="item" @click="navTo('discountCoupon')">
            <el-icon>
              <Discount />
            </el-icon>
            {{ $t('home.coupons') }}
          </div>
          <div class="item" @click="navTo('OrderCabinet')">
            <el-icon>
              <Switch />
            </el-icon>
            {{ $t('home.battayReturnList') }}
          </div>
          <div class="item" @click="navTo('OrderBattery')">
            <el-icon>
              <Download />
            </el-icon>
            {{ $t('home.newRentList') }}
          </div>
          <div class="item" @click="navTo('AfterSellFeedBack')">
            <el-icon>
              <Bell />
            </el-icon>
            {{ $t('home.feedback') }}
          </div>
          <div class="item" @click="navTo('BillRefund')">
            <el-icon>
              <Remove />
            </el-icon>
            {{ $t('home.refundReview') }}
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="choose">
        <el-cascader style="width: 100%;" :clearable="true" v-model="formData.valOperator" :props="formData.props"
          :options="render.cascade_options_direct" popper-append-to-body="false" placement='left-start'
          @change="func.changeOperator" />
      </div>
      <div class="personal" @click="navTo('MemberProfile')">
        <div class="up">
          <div class="image"></div>
          <div class="right">
            <div class="account">{{ userData.name || userData.userName }}</div>
            <div class="hint">{{ quotes }}</div>
          </div>

        </div>
        <div class="down">
          <div class="list">
            <div class="item">{{ userData.roleName }}</div>
            <div class="item" v-if="userData.roleId === 14">{{ userData.lastLoginTime }}</div>
            <div class="item" v-else>{{ userData.codeName }}</div>
            <!-- <div class="item" v-if="userData.roleId !== 14">127.0.0.1</div>
            <div class="item" v-if="userData.roleId !== 14">甘肃如电-庆阳如电</div> -->
          </div>
        </div>
      </div>
      <div class="feedback">
        <div class="header">
          <div class="title">{{ $t('home.bill') }}</div>
          <div class="more" @click="navTo('BillRecord')">{{ $t('home.more') }}</div>
        </div>
        <div class="list" v-for="item in billList.arr">
          <div class="item" v-if="item.Type == 1 || item.Type == 2 || item.Type == 4 || item.Type == 10">
            <span v-if="item.Type == 1">
              <span class="combo">{{ $t('home.combo') }}</span>
              <span>{{ item.UserName }}{{ $t('home.pay') }}{{ item.Money }}{{ $t('g.currency') }}。</span>
            </span>
            <span v-if="item.Type == 2">
              <span class="balance">{{ $t('home.topUp') }}</span>
              <span>{{ item.UserName }}{{ $t('home.topUp') }}{{ item.Money }}{{ $t('g.currency') }}。</span>
            </span>
            <span v-if="item.Type == 10">
              <span class="charge">{{ $t('home.charge') }}</span>
              <span>{{ item.UserName }}{{ $t('home.pay') }}{{ item.Money }}{{ $t('g.currency') }}。</span>
            </span>
            <span v-if="item.Type == 4">
              <span class="refund">{{ $t('home.Refund') }}</span>
              <span>{{ item.UserName }}{{ $t('home.Refund') }}{{ item.Money }}{{ $t('g.currency') }}。</span>
            </span>
            <!-- <span v-if="item.Type == 3">
              <span class="refund">押金</span>
              <span>{{ item.UserName }}退款{{ item.Money }}元。</span>
            </span> -->
            <span class="time1">{{ item.createdStr }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, computed, nextTick, ref, shallowRef, watch } from "vue";
import CountUp from 'vue-countup-v3'
import { shortcuts2, updateOperators, operatorList, cascade_options_direct } from "@/data/default";
import { toDay, navTo } from "@/common/func";
import axios from "axios";
import * as echarts from 'echarts'
import { ElMessage, dayjs } from "element-plus";
import i18n from "@/data/i18n/index";
import { isOperator, isAdmin } from "@/data/token";
import { useRouter } from 'vue-router';
import storage from '@/common/storage'
const t = i18n.global.t
const router = useRouter();
const render = {
  shortcuts2,
  operatorList,
  cascade_options_direct
}
const formData = reactive({
  props: {
    children: "Children",
    label: "Name",
    value: "CodePath",
    emitPath: false,
    checkStrictly: true
  },
  valOperator: ''
})
// timeIndex = 
let week = dayjs().day()
let hour = dayjs().hour()
let quotesIndex = (week + hour) % 25
// let quotesIndex = Math.floor(Math.random() * 25)
let quotes = reactive('')
if (storage.get('locale') == 'En') {
  quotesIndex = (week + hour) % 15
  // quotesIndex = Math.floor(Math.random() * 15)
  quotes = [
    'While there is life,there is hope.',
    'I am a slow walker,but I never walk backwards.',
    'Never underestimate your power to change yourself!',
    'Nothing is impossible!',
    'No way is impossible to courage.',
    'I will greet this day with love in my heart.',
    'All things come to those who wait.',
    'No cross, no crown.',
    'Cease to struggle and you cease to live.',
    'Do what you say,say what you do.',
    'Never, never, never, never give up.',
    'Even a great life is only a life until you make it.',
    'Difficult roads often lead to beautiful destinations.',
    'The most effective way to do it, is to do it.',
    'A goal is a dream with a deadline.',
  ][quotesIndex]
} else {
  quotes = [
    '蒲公英追逐远方，手心里盛满希望。',
    '在时光里享受温暖，在流年里忘记花开。',
    '愿你出走半生，归来仍是少年。',
    '把握每一个瞬间，珍惜眼前的风景，留下永远的纪念。',
    '每一天和每个微不足道的成绩都是一种礼物。',
    '君子藏器于身，待时而动。',
    '天行健，君子以自强不息。地势坤，君子以厚德载物。',
    '我本向阳，无需悲伤。',
    '去留无意，闲看庭前花开花落；宠辱不惊，漫随天外云卷云舒。',
    '老当益壮，宁移白首之心；穷且益坚，不坠青云之志。',
    '海纳百川，有容乃大；壁立千仞，无欲则刚。',
    '愿有岁月可回首，且以深情共白头。',
    '给生命一个微笑，放手过去，享受现在，拥抱未来。',
    '业精于勤，荒于嬉；行成于思，毁于随。',
    '博观而约取，厚积而薄发。',
    '花非花，雾非雾。夜半来，天明去。来如春梦几多时?去似朝云无觅处。',
    '等闲识得东风面，万紫千红总是春。',
    '人生若只如初见，何事秋风悲画扇。',
    '面朝大海，春暖花开。',
    '我有明珠一颗，久被尘劳关锁。今朝尘世光生，照破山河万朵。',
    '当时只道是寻常，待到懂时已沧桑。',
    '侯非侯，王非王，千乘万骑走北芒。',
    '不知古道上的风从何处起，可它去往的是故里。',
    '弱小，并非服从恐惧的理由。',
    '且行且忘且随风，且行且看且从容。',
  ][quotesIndex]
}

const statList = reactive({
  activeIndex: 1,
  timeIndex: 7,
  valTime: '',
  incomeTotal: 0,
  newRentTotal: 0,
  replaceBatteryTotal: 0,
  comboTotal: 0,
  returnBatteryTotal: 0,
  incomeTotal: 0,
  unit: '',
  xAxisData: [],
  yAxisData: [],
})
const timeType = reactive({
  timeIndex: 7
})
const userData = reactive({
  roleId: '',
  roleName: '',
  codeName: '',
  lastLoginTime: '',
  ip: '',
  userName: '',
  name: '',
})
const devAndBatNum = reactive({
  onlineDev: 0,
  offlineDev: 0,
  onlineBat: 0,
  offlineBat: 0,
})
const billList = reactive({
  arr: []
})
const echartDict = shallowRef({
  myEchart: '',
  option: {}
})
onMounted(() => {
  func.getUserData()
  func.getDevAndBatNum()
  func.getAllTotal()
  func.getStatData(1, 7)
  func.getBill()
  const myEchartDiv = document.getElementById('myEchart1')
  echartDict.myEchart = echarts.init(myEchartDiv)
  // let option = {}
  // myEchart.setOption(option)
  // setTimeout(() => {
  //   echartDict.myEchart.setOption(option)
  // }, 1000)
  window.addEventListener("resize", function (event) {
    echartDict.myEchart.resize()
  })
})
watch(timeType, (newVal, oldVal) => {

  func.getAllTotal()
})
const func = {
  ICountUpReady(instance, CountUp) {
    const that = this;
    instance.update(devAndBatNum.onlineDev + 100);
  },
  changeOperator() {
    func.getDevAndBatNum()
    func.getAllTotal()
    func.getStatData(statList.activeIndex)
    func.getBill()
  },
  async getUserData() {
    let res = await axios.get("/admin/userDetail")
    if (res) {
      userData.roleId = res.RoleId
      userData.roleName = res.Role?.Title
      userData.name = res.Name
      userData.userName = res.Username
      userData.codeName = res.PathAgentInfo?.NamePath
      userData.lastLoginTime = res.LastLoginTime ? dayjs.unix(res.LastLoginTime).format('YYYY-MM-DD HH:mm:ss') : ''
      // userData.ip = res.Id
      // console.log(JSON.stringify(userData));

    }
  },
  async getDevAndBatNum() {
    let params = {}
    if (formData.valOperator) {
      params.CodePath = formData.valOperator
    }
    let res = await axios.get('/admin/battery/getstat', {
      params
    })
    if (res) {
      devAndBatNum.onlineDev = res.OnlineBox
      devAndBatNum.offlineDev = res.OfflineBox
      devAndBatNum.onlineBat = res.OnlineCount
      devAndBatNum.offlineBat = res.OfflineCount
    }
  },
  async getAllTotal() {
    let params = {}
    let day = 7
    if (formData.valOperator) {
      params.codePath = formData.valOperator
    }
    if (timeType.timeIndex) {
      day = timeType.timeIndex
    }
    if (timeType.timeIndex == 60 && statList.valTime) {
      params.startAt = dayjs(dayjs(statList.valTime[0]).format('YYYY-MM-DD 00:00:01')).unix()
      params.endAt = dayjs(dayjs(statList.valTime[1]).format('YYYY-MM-DD 23:59:59')).unix()
    } else {
      params.startAt = dayjs(dayjs().subtract(day, 'day').format('YYYY-MM-DD 00:00:01')).unix()
      params.endAt = dayjs(dayjs().format('YYYY-MM-DD 23:59:59')).unix()
    }
    let res = await axios.get('/admin/homedata/gettotalnum', {
      params
    })
    if (res) {
      statList.incomeTotal = res.TotalMoney
      statList.newRentTotal = res.TotalNewRent
      statList.replaceBatteryTotal = res.TotalExchange
      statList.comboTotal = res.TotalCombo
      statList.returnBatteryTotal = res.TotalReturn
    }
  },
  async getStatData(statType = 1, time) {
    let params = {}

    let day = 7
    if (time) {
      day = time
    } else {
      day = timeType.timeIndex
    }
    switch (time) {
      case 7:
        timeType.timeIndex = 7
        break;
      case 30:
        timeType.timeIndex = 30
        break;
      case 60:
        timeType.timeIndex = 60
        break;
    }
    if (formData.valOperator) {
      params.codePath = formData.valOperator
    }
    if (timeType.timeIndex == 60 && statList.valTime) {
      params.startAt = dayjs(dayjs(statList.valTime[0]).format('YYYY-MM-DD 00:00:01')).unix()
      params.endAt = dayjs(dayjs(statList.valTime[1]).format('YYYY-MM-DD 23:59:59')).unix()
    } else {
      params.startAt = dayjs(dayjs().subtract(day, 'day').format('YYYY-MM-DD 00:00:01')).unix()
      params.endAt = dayjs(dayjs().format('YYYY-MM-DD 23:59:59')).unix()
    }

    // params.codePath = ''
    // let url = `/admin/homedata/getgain`
    let url = ''
    statList.activeIndex = statType

    switch (statType) {
      case 1:
        url = `/admin/homedata/getgain`
        statList.unit = t('g.currency')
        break;
      case 2:
        url = `/admin/homedata/getnewrent`
        statList.unit = t('bigScreen.digit')
        break;
      case 3:
        url = `/admin/homedata/getexchangenum`
        statList.unit = t('bigScreen.PCS')
        break;
      case 4:
        url = `/admin/homedata/getcombonum`
        statList.unit = t('bigScreen.PCS')
        break;
      case 5:
        url = `/admin/homedata/getreturnnum`
        statList.unit = t('bigScreen.PCS')
        break;
    }
    let res = await axios.get(url, {
      params
    })
    // console.log(JSON.stringify(res.DataTrend));

    if (res.DataTrend) {
      statList.xAxisData.length = 0
      statList.yAxisData.length = 0
      res.DataTrend.forEach((item) => {
        statList.xAxisData.push(item.OrderDate)
        statList.yAxisData.push(item.Value)
      })
      // statList.xAxisData.push(...x)
      // statList.yAxisData.push(...y)
      // statList.incomeTotal = res.TotalMoney
      switch (statType) {
        case 1:
          statList.incomeTotal = res.Total
          break;
        case 2:
          statList.newRentTotal = res.Total
          break;
        case 3:
          statList.replaceBatteryTotal = res.Total
          break;
        case 4:
          statList.comboTotal = res.Total
          break;
        case 5:
          statList.returnBatteryTotal = res.Total
          break;
      }
      func.setEchartOption()
      echartDict.myEchart.setOption(echartDict.option)
    }
    // console.log(JSON.stringify(statList));
  },
  jump(type, obj, to) {
    // console.log('路由对象', type, obj)
    router.push({
      name: to,
      state: {
        params: {
          type: type,
          value: {
            name: obj.name,
            val: obj.val
          }
        }
      }
    });
  },
  setEchartOption() {
    echartDict.option = {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: statList.xAxisData
      },
      tooltip: {
        tigger: 'item'
        // className: 'custom-tooltip',
        // formatter: (params) => {
        //   return 123
        // }
      },
      grid: {
        show: true,
        top: '5%',
        left: '3%',
        bottom: '5%',
        right: '5%',
        containLabel: true,
        // 设置折线图表格区域背景颜色
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      },
      yAxis: {
        type: 'value',
        axisLabel: { formatter: '{value}' + statList.unit }
      },

      series: [
        {
          data: statList.yAxisData,
          type: 'line',
          lineStyle: {
            color: '#409eff'
          },
          itemStyle: {
            color: '#409eff'
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#409eff" },
              { offset: 0.5, color: "#ddeaf7" },
              { offset: 1, color: "#fff" },
            ]),
            opacity: 0.5
          }
        }
      ]
    };
  },
  async getBill() {
    let params = {}
    if (formData.valOperator) {
      params.agentCode = formData.valOperator
    }
    params.isHomeData = 1
    let res = await axios.get(`/admin/moneyLog/get`, {
      params
    })
    if (res.List && res.List.LogInfo) {
      res.List.LogInfo.forEach(item => {
        item.createdStr = dayjs.unix(item.CreatedAt).format('MM-DD HH:mm')
        if (item.Type == 1 && item.BusinessSn) {
          if (item.BusinessSn.includes('EBC')) {
            item.Type = 10
          }
        }
      });
      billList.arr = []
      billList.arr.push(...res.List.LogInfo)
      // console.log(JSON.stringify(billList.arr));

    }
  }
}

</script>
<style lang="scss" scoped>
$gap: 10px; //
$boundaryMagin: 5px;
$borderRadius: 5px;
$BgColor: #ffffff;
$titleFontSize: 20px;
$bottomBorder: #cfd9e6;

.home {
  height: calc(100% - 3rem);
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  display: flex;
  padding: 0;
  background-color: #c5d2dc;
  // background: linear-gradient(rgba(122, 131, 138, 0.5), rgba(122, 131, 138, 0.5) 60%, rgba(122, 131, 138, 0.7) 100%);
  // background-image: url(../assets/img/login_bg_new2.png);
  // background-size: cover;
  // filter: blur(2px);
  // backdrop-filter: blur(1px);

  >.left {
    flex: 3;
    display: flex;
    flex-direction: column;
    height: 100%;
    // overflow: scroll;
    margin-right: $gap;
    margin-top: $boundaryMagin;
    // margin-left: 12px;
    margin-left: $boundaryMagin;

    >.count {
      // flex: 120;
      height: 90px;
      display: flex;
      justify-content: space-between;

      >.item {
        width: 24.5%;
        // background-color: $BgColor;
        background-image: linear-gradient(to right, #ffffff 0%, #f8fbfd 50%, #ffffff 100%);
        border-radius: $borderRadius;
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
        // align-items: center;
        // text-align: center;
        padding: 10px 0;

        >.num {
          flex: 2;
          font-size: 32px;
          color: #409eff;
        }

        >.name {
          flex: 1;
          font-size: 20px;
        }

        &:hover {
          background-image: linear-gradient(#178fba 0%, #178fba 100%);
          background-color: #178fba;
          color: #fff;
          cursor: pointer;

          >.num {
            color: #fff;
          }
        }


        >.warn {
          color: #cd4f59;
        }
      }
    }

    >.graph-data {
      flex: 474;
      background-color: $BgColor;
      margin: $gap 0;
      border-radius: $borderRadius;
      display: flex;
      flex-direction: column;

      >.up {
        // flex: 1;
        display: flex;
        justify-content: space-between;
        // background-color: #409eff;
        border-bottom: 1px solid $bottomBorder;
        align-items: center;
        padding: 0 20px;
        height: 54px;

        >.title {
          font-size: $titleFontSize;

        }

        >.right {
          width: 450px;
          display: flex;
          justify-content: right;

          >.seven-day {
            border: 1px solid rgb(219, 214, 214);
            padding: 0px 16px;
            border-radius: 16px;
            cursor: pointer;
            margin-right: 10px;

            &:hover {
              color: #409eff;
              border: 1px solid #409eff;
            }
          }

          >.one-month {
            border: 1px solid rgb(219, 214, 214);
            // background-color: #ddeaf7;
            padding: 0px 16px;
            border-radius: 16px;
            cursor: pointer;
            margin-right: 10px;

            &:hover {
              color: #409eff;
              border: 1px solid #409eff;
            }
          }

          >.active {
            border: 1px solid #409eff;
            background-color: #ddeaf7;
            color: #409eff;
          }
        }

        :deep .period {
          .el-date-editor {
            width: 160px;
          }

          .el-range-input {
            width: 60px;
          }
        }
      }

      >.down {
        flex: 1;
        display: flex;
        flex-direction: column;
        // padding-top: 10px;

        >.list {
          // flex: 1;
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 108px;
          // background-color: pink;

          >.item {
            flex: 1;
            // background-color: pink;
            // border-right: 1px solid rgb(203, 198, 198);
            margin: 0 8px;

            // text-align: left;
            >.num {
              font-size: 24px;
            }

            >.desc {
              font-size: 18px;
            }

            &:hover {
              padding: 15px 0;
              background-color: #4395f7;
              background-image: linear-gradient(to bottom, #4395f7, #95b6de 95%, #95b6de);
              color: #fff;
              border-radius: 5px;
              position: relative;
              cursor: pointer;

              &::before {
                position: absolute;
                bottom: -12px;
                left: 50%;
                transform: translate(-50%, 0%);
                content: '';
                border-top: 12px solid #95b6de;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
              }
            }
          }

          >.delimiter {
            height: 65px;
            border-right: 1px solid rgb(203, 198, 198);
          }



          >.active {
            padding: 15px 0;
            background-color: #4395f7;
            background-image: linear-gradient(to bottom, #4395f7, #95b6de 95%, #95b6de);
            color: #fff;
            border-radius: 5px;
            position: relative;

            &::before {
              position: absolute;
              bottom: -12px;
              left: 50%;
              transform: translate(-50%, 0%);
              content: '';
              border-top: 12px solid #95b6de;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
            }
          }
        }

        >.graph {

          flex: 3;
          // background-color: #409eff;

          #myEchart1 {
            // background-color: pink;
          }
        }
      }
    }

    >.jump {
      // flex: 164;
      height: 130px;
      background-color: $BgColor;
      margin-bottom: $gap;
      border-radius: $borderRadius;
      display: flex;
      flex-direction: column;

      >.title {
        text-align: left;
        flex: 1;
        font-size: $titleFontSize;
        padding: 10px 20px;
        border-bottom: 1px solid $bottomBorder;
      }

      >.list {
        display: flex;
        flex: 3;
        align-items: center;
        justify-content: space-around;
        font-size: 18px;

        >.item {
          padding: 10px 12px;
          border-radius: 5px;
          // background: linear-gradient(#4696f6, #78acec);
          border: 1px solid #a6b3bc;
          background-color: #f7f8f8;
          color: #1c527a;
          display: flex;
          align-items: center;
          // justify-content: space-between;

          // box-shadow: rgba(60, 64, 67, 0.5) 0px 1px 3px 0px, rgba(60, 64, 67, 0.15) 0px 2px 3px 2px;
          // color: #f8f4f4;
          &:hover {
            border: 1px solid #178fba;
            background-color: #178fba;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }

  >.right {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: $boundaryMagin;
    margin-top: $boundaryMagin;

    >.choose {
      // flex: 1;
      // height: 50px;
      // background-color: $BgColor;
      border-radius: $borderRadius;
    }

    >.personal {
      // flex: 5;
      background-color: $BgColor;
      margin: $gap 0;
      border-radius: $borderRadius;
      display: flex;
      flex-direction: column;
      height: 150px;
      background-image: url(../assets/img/home-card-bg.png);
      background-size: cover;
      // background: linear-gradient(to right bottom, #559de5, #75a0cb 70%, #6ca0d4 100%);
      cursor: pointer;

      >.up {
        height: 113px;
        border-bottom: 1px solid #f1eeee;
        display: flex;
        // justify-content: space-around;
        align-items: center;
        color: #fff;

        >.image {
          // flex: 1;
          width: 60px;
          height: 60px;
          background-color: #e9ecef;
          border-radius: 60px;
          margin-left: 20px;
          background: url(../assets/img/home-avatar.png);
          background-size: cover;
        }

        >.right {
          flex: 1;
          text-align: left;
          margin-left: 10px;

          >.account {
            margin-bottom: 8px;
          }
        }
      }

      >.down {
        // flex: 1;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        height: 37px;
        line-height: 37px;
        color: #615454;

        >.list {
          display: grid;
          grid-template-columns: 42% 58%;

          font-size: 15px;

          >.item {
            flex: 1;
            text-align: left;
            // padding-top: 6px;
            padding-left: 10px;
          }
        }
      }
    }

    >.feedback {
      flex: 12;
      background-color: $BgColor;
      margin-bottom: $gap;
      border-radius: $borderRadius;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #9eaab4;
      }

      >.header {
        height: 50px;
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        padding: 0 10px;
        border-bottom: 1px solid $bottomBorder;

        >.title {
          font-size: $titleFontSize;

        }

        >.more {
          color: gray;
          cursor: pointer;

          &:hover {
            color: #409eff;
          }
        }
      }

      >.list {
        margin-top: 10px;

        >.item {
          text-align: left;
          padding-left: 10px;
          padding-right: 5px;
          margin-bottom: 17px;
          font-size: 14px;
          display: flex;
          justify-content: space-between;

          >span:first-child {
            flex: 3;
          }

          >span:last-child {
            // flex: 1.1;
            width: 82px;
            color: rgb(113, 112, 112);
            text-align: right;
          }

          .combo {
            border: 1px solid green;
            color: green;
            background-color: rgb(240, 251, 240);
            border-radius: 4px;
            padding: 0px 4px;
            margin-right: 6px;
            font-size: 14px;
          }

          .charge {
            border: 1px solid green;
            color: green;
            background-color: rgb(240, 251, 240);
            border-radius: 4px;
            padding: 0px 4px;
            margin-right: 6px;
            font-size: 14px;
          }

          .refund {
            border: 1px solid rgba(255, 0, 0, 0.6);
            color: rgba(255, 0, 0, 0.6);
            background-color: rgba(250, 238, 238, 0.5);
            border-radius: 4px;
            padding: 0px 4px;
            margin-right: 6px;
            font-size: 14px;
          }

          .balance {
            border: 1px solid #3089dc;
            color: #3089dc;
            background-color: #e9eef4;
            border-radius: 4px;
            padding: 0px 4px;
            margin-right: 6px;
            font-size: 14px;

          }

          // .time1 {
          //   // color: red;
          //   // width: 88px; // height: 80px;
          //   text-align: right;
          // }
        }

        // >.processed {
        //   &::before {
        //     content: '已处理';
        //     border: 1px solid green;
        //     color: green;
        //     background-color: rgb(240, 251, 240);
        //     border-radius: 4px;
        //     padding: 1px 5px;
        //     margin-right: 10px;
        //     font-size: 14px;

        //   }
        // }

        // >.unprocessed {
        //   &::before {
        //     content: '未处理';
        //     border: 1px solid #e49217;
        //     color: #e49217;
        //     background-color: rgb(250, 242, 242);
        //     border-radius: 4px;
        //     padding: 1px 5px;
        //     margin-right: 10px;
        //     font-size: 14px;

        //   }
        // }
      }
    }
  }

}
</style>
