import { reactive } from 'vue'
import axios from 'axios'
import i18n from '@/data/i18n/index'
const t = i18n.global.t

export const userMenuList = reactive({
  data:[]
})

export const loadUserMenu = async (userId)=>{
  setTimeout(async ()=>{
    let res = await axios.get("/admin/role/userAuth?user_id="+userId)
    const userMenu = []
    // console.log(JSON.stringify(res),23);
    const findById = (id)=>{
      for(let item of res){
        if(item.Id === id){
          return item
        }
      }
      return false
    }
      for(let top of menu){
        let menuItem = findById(top.id)
        if(menuItem !== false){
          let topTmp = {
            // title:menuItem.ModuleName,
            title:top.title,
            id:menuItem.Id.toString(),
            icon:top.icon,
            item:[]
          }
          //2级菜单
          for(let top2 of top.item){
            let menuItem2 = findById(top2.id)
            if(menuItem2 !== false){
              let top2Tmp = {
                // title:menuItem2.ModuleName,
                title:top2.title,
                id:menuItem2.Id,
                name:menuItem2.Url,
              }
              topTmp.item.push(top2Tmp)
            }
          }
          if(topTmp.item.length > 0){
            userMenu.push(topTmp);
          }
        }
      }
    userMenuList.data = userMenu
    // console.log("userMenu",userMenu)
  },100)
}
export const findTitleByNavName = (name) => {
  if(!name){
    return ""
  }
    for(let item1 of menu){
      for(let item2 of item1.item){
          if(item2.name == name){
            return item2.title
          }
      }
    }
  return ""
}
const menu = [
      {
        title: t('menu.decision.analyze'),
        id:100,
        icon: 'PieChart',
        item: [
          {
            id:160,
            title: t('menu.decision.statistics'),
            name: 'DecisionStatistics'
          },
          {
            id:127,
            title: t('menu.decision.hot'),
            name: 'DecisionHot'
          },
          {
            id:123,
            title: t('menu.distribution_site'),
            name: 'OperationPoints'
          },
          // {
          //   id:149,
          //   title: t('menu.distribution_battery'),
          //   name: 'OperationBattery'
          // },
          // {
          //   id:124,
          //   title: t('menu.decision.user'),
          //   name: 'OperationUsers'
          // },
          // {
          //   id:125,
          //   title: t('menu.decision.properties'),
          //   name: 'OperationProperties'
          // },
          // {
          //   id:126,
          //   title: t('menu.decision.order'),
          //   name: 'OperationOrders'
          // }
        ]
      },
      {
        id:94,
        title: t('menu.member'),
        icon: 'Discount',
        item: [
          {
            id:109,
            title: t('menu.member_list'),
            name: 'MemberList'
          },
          {
            id:108,
            title: t('menu.profile'),
            name: 'MemberProfile'
          },
          {
            id:159,
            title: t('menu.agents_switch'),
            name: 'agentsSwitch'
          },
        ]
      },
      {
        id:96,
        title: t('menu.orders.manage'),
        icon: 'Memo',
        item: [
          {
            id:111,
            title: t('menu.orders.rent'),
            name: 'OrderBattery'
          },
          {
            id:112,
            title: t('menu.orders.exchange'),
            name: 'OrderCabinet'
          },
          {
            id:113,
            title: t('menu.orders.return'),
            name: 'OrderBatteryReturn'
          },
          {
            id:148,
            title: t('menu.orders.temporary_record'),
            name: 'temporaryRecord'
          },
          {
            id:117,
            title: t('menu.orders.setmenu'),
            name: 'OrderCombo'
          },
          {
            id:133,
            title: t('menu.orders.offline'),
            name: 'OrderOffline'
          },
          {
            id:116,
            title: t('menu.orders.recharge'),
            name: 'OrderRecharge'
          },
          {
            id:140, 
            title: t('menu.open_solot'),
            name: 'openSolotRecord'
          },
          {
            id:114,
            title: t('menu.orders.cbox'),
            name: 'OrderCbox'
          },
          {
            id:115,
            title: t('menu.orders.pile'),
            name: 'OrderPile'
          },
          {
            id:170,
            title:  t('menu.orders.carPile'),
            name: 'OrderCarPile'
          },
         
        
        ]
      },
      {
        id:91,
        title: t('menu.devManage'),
        icon: 'Box',
        item: [
          {
            id:101,
            title: t('menu.ebox_list'),
            name: 'EboxList'
          },
          {
            id:102,
            title: t('menu.cbox_list'),
            name: 'CboxList'
          },
         
          {
            id:103,
            title: t('menu.pile_list'),
            name: 'PileList'
          },
          {
            id:171,
            title: t('menu.car_pile_list'),
            name: 'CarPileS'
          },
          // {
          //   id:129,
          //   title: t('menu.cpbox_list'),
          //   name: 'CPboxList'
          // },
          {
            id:104,
            title: t('menu.battery_list'),
            name: 'BatteryList'
          },
          {
            id:132,
            title: t('menu.card_list'),
            name: 'CardList'
          },
          
        ]
      },
      {
        id:143,//活动管理
        title: t('menu.activity_manage'),
        icon: 'Calendar',
        item: [
          // {
          //   id:147, 
          //   title: t('menu.restriction_rule'),
          //   name: 'restrictionRule'
          // },
          {
            id:156, 
            title: t('menu.announcement_list'),
            name: 'announcementList'
          },
          {
            id:146, 
            title: t('menu.discount_coupon'),
            name: 'discountCoupon'
          },
          {
            id:144, 
            title: t('menu.recruit_manage'),
            name: 'activityManage'
          },
          {
            id:145, 
            title: t('menu.award_manage'),
            name: 'awardManage'
          }
          ,
          {
            id:151, 
            title: t('menu.recruit_new'),
            name: 'recruitNew'
          }
         
        ]
      },
      {
        id:97,
        title: t('menu.bill_manage'),
        icon: 'CreditCard',
        item: [
          {
            id:118,
            title: t('menu.turnover_record'),
            name: 'BillRecord'
          },
          {
            id:119,
            title: t('menu.settle'),
            name: 'BillSettle'
          },
          {
            id:120,
            title: t('menu.refund'),
            name: 'BillRefund'
          },
          {
            id:152, 
            title: t('menu.withdraw'),
            name: 'withdrawFunds'
          },
          {
            id:121,
            title: t('menu.badbill'),
            name: 'BillBad'
          },
          {
            id:130,
            title: t('menu.refund_record'),
            name: 'BillRefundRecord'
          },
          {
            id:162,
            title: t('menu.overdue'),
            name: 'overdue'
          },
        ]
      },
     
      {
        id:134,
        title: t('menu.operationManage'),
        icon: 'User',
        item: [
          {
            id:122,
            title: t('menu.set_menu_manage'),
            name: 'ExpensesCombo'
          },
          {
            id:161,
            title: t('menu.ExpensesBeOverdue'),
            name: 'ExpensesBeOverdue'
          },
          {
            id:155,
            title: t('menu.electricity_stat'),
            name: 'electricityStat'
          },
         
          {
            id:110,
            title: t('menu.agent_list'),
            name: 'MemberAgents'
          },
         
        ]
      },
    
      {
        id:95,
        title: t('menu.after_sale_service'),
        icon: 'Service',
        item: [
          {
            id:107,
            title: t('menu.feedback'),
            name: 'AfterSellFeedBack'
          },
          {
            id:142, 
            title: t('menu.alert_record'),
            name: 'alertRecord'
          },
          // {
          //   id:168, 
          //   title: '工单设置',
          //   name: 'workOrderSet'
          // },
          {
            id:169, 
            title: t('menu.workOrderList'),
            name: 'workOrderList'
          },
        ]
      },
    
      {
        id:85,
        title: t('menu.log_info'),
        icon: 'Notebook',
        item: [
          {
            id:90,
            title: t('menu.log_api'),
            name: 'LogApi'
          }
          ,
          {
            id:153,
            title: t('menu.msg_log'),
            name: 'msgLog'
          },
          {
            id:154, 
            title:  t('menu.open_solot_record'),
            name: 'openSolot'
          }
        ]
      },
      // {
      //   id:141,
      //   title: t('menu.operator_list'),
      //   icon: 'Stopwatch',
      //   item: [
        
      //   ]
      // }
      // ,
   

      {
        id:84,
        title: t('menu.rights_manage'),
        icon: 'Edit',
        item: [
          {
            id:88,
            title: t('menu.managers'),
            name: 'RightsManager'
          },
          {
            id:87,
            title: t('menu.role_manage'),
            name: 'RightsRole'
          },
          {
            id:86,
            title: t('menu.rights_set'),
            name: 'Rights'
          },
          {
            id:164,
            title: t('menu.SecondTierAgent'),
            name: 'SecondTierAgent'
          },
          {
            id:165,
            title: t('menu.ThirdTierAgent'),
            name: 'ThirdTierAgent'
          },
          {
            id:166,
            title: t('menu.FourthTierAgent'),
            name: 'FourthTierAgent'
          }
        ]
      },

]
